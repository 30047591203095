<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="products-modal" @click.stop>
            <div class="modal-content" @click.stop> <!-- stop the click event from propagating to the overlay -->
                <h2>Create a new Password</h2>
                <table>
                    <tr>
                        <div class="password-input">
                            <input :type="showPassword ? 'text' : 'password'" class="form-control rounded" id="password"
                                name="password" placeholder="Create Password" v-model="newUser.password" />
                            <i class="eye-icon" @click="toggleShowPassword"
                                :class="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"></i>

                        </div>
                    </tr>
                    <tr>
                        <div class="form-group">
                            <input :type="showPassword ? 'text' : 'password'" class="form-control rounded"
                                id="reenteredPassword" name="reenteredPassword" placeholder="Re-enter Password"
                                v-model="newUser.reenteredPassword" />
                        </div>
                    </tr>
                    <tr>
                        <button style="width: 40%;" class="saveButton" @click="saveButton">Save</button>
                    </tr>
                </table>
            </div>
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
                <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            users: [],
            newUser: {
                password: '',
                reenteredPassword: '',
            },
            showPassword: false,
            errorMessage: '',
            showAlert: false,
        };
    },

    mounted() {
        this.fetchData();
        window.addEventListener('show-alert', this.handleShowAlert);
    },
    beforeUnmount() {
        window.removeEventListener('show-alert', this.handleShowAlert);
    },


    methods: {
        async fetchData() {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const user_id = localStorage.getItem('user_id');

                let response = await axios.get(`/api/users/${user_id}`, { headers });

                this.users = response.data;
                console.log(response.data);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },

        closeModal() {
            this.$emit('close');
            location.reload();
        },
        async saveButton() {
            // Check if values are set before making the API call
            if (this.newUser.password) {
                if (this.newUser.password !== this.newUser.reenteredPassword) {
                    this.errorMessage = 'Passwords do not match';
                    this.showAlert = true;
                    return;
                }
                try {
                    const token = localStorage.getItem('token');
                    const headers = { Authorization: `Bearer ${token}` };
                    const user_id = localStorage.getItem('user_id');
                    console.log(this.users);
                    const data = {
                        name: this.users.name,
                        email: this.users.email,
                        role: this.users.role,
                        category: this.users.category,
                        UIN: this.users.UIN,
                        date_of_birth: this.users.date_of_birth,
                        place_of_birth: this.users.place_of_birth,
                        password: this.newUser.password
                    };
                    console.log("data", data);
                    const response = await axios.put(`/api/users/${user_id}`, data, { headers });

                    console.log(response);
                    if (response.status === 200) {
                        window.alert('Password updated Successfully');

                        this.$router.push('/trainee-home');
                    } else {
                        window.dispatchEvent(new CustomEvent('show-alert', { detail: "Password update failed" }));
                    }
                } catch (error) {
                    console.error("There was an error with updating the password:", error);
                }
            } else {
                console.error("Please enter a password.");
            }
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },

    },

};

</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 90%;
    min-height: 30%;
    min-width: 40%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 1001;
}

.specificdropdown,
.productsearch,
.specificdropdown {
    height: 40px;
    /* adjust this value as needed */
    margin-bottom: 10px;
    width: 80%;
}


.modal-content {
    border: white;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}

.eye-icon {
    position: absolute;
    top: 58px;
    right: 20px;
    cursor: pointer;
    color: grey;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

h2 {
    margin-bottom: 1.5rem;
}

.alert-danger {
    color: red;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-bottom: 0px;
    margin-top: 30px;
}
</style>