<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li :class="{ 'page-item': true, disabled: currentPage === 1 }" @click.prevent="goToFirstPage">
        <a href="#" class="page-link">First</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === 1 }" @click.prevent="previousPage">
        <a href="#" class="page-link">Previous</a>
      </li>
      <li v-for="page in displayedPages" :key="page" :class="{ active: page === currentPage }" @click.prevent="goToPage(page)"
        class="page-item">
        <a href="#" class="page-link">{{ page }}</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === totalPages }" @click.prevent="nextPage">
        <a href="#" class="page-link">Next</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === totalPages }" @click.prevent="goToLastPage">
        <a href="#" class="page-link">Last</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    displayedPages() {
      const maxVisiblePages = 5;
      const halfVisiblePages = Math.floor(maxVisiblePages / 2);
      let startPage = Math.max(1, this.currentPage - halfVisiblePages);
      let endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);

      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    }
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit('page-change', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('page-change', this.currentPage + 1);
      }
    },
    goToFirstPage() {
      if (this.currentPage > 1) {
        this.$emit('page-change', 1);
      }
    },
    goToLastPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('page-change', this.totalPages);
      }
    },
    goToPage(page) {
      this.$emit('page-change', page);
    }
  }
};
</script>

<style scoped>
.page-item.selected {
  background-color: #02092b;
}
.page-item.active {
  z-index: 0 !important;
}
</style>
